import { cloneDeep } from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- Fonds BCJ Défensif ----------*****
const fondsBcjDefensifAa = {
  productId: "fonds-bcj-defensif-aa",
  storeModule: "fondsBcjDefensifAa",
  productName: "Fonds BCJ Défensif AA - Distribution",
  productShortName: "Fonds BCJ Défensif",
  productColor: ORG_COLOR,
  productShareClassRoot: "fonds-bcj-defensif",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const fondsBcjDefensifAt = cloneDeep(fondsBcjDefensifAa);
fondsBcjDefensifAt.productId = "fonds-bcj-defensif-at";
fondsBcjDefensifAt.storeModule = "fondsBcjDefensifAt";
fondsBcjDefensifAt.productName = "Fonds BCJ Défensif AT - Capitalisation";
fondsBcjDefensifAt.isDefaultShareClass = false;

const fondsBcjDefensifP = cloneDeep(fondsBcjDefensifAa);
fondsBcjDefensifP.productId = "fonds-bcj-defensif-p";
fondsBcjDefensifP.storeModule = "fondsBcjDefensifP";
fondsBcjDefensifP.productName = "Fonds BCJ Défensif P - Prévoyance";
fondsBcjDefensifP.isDefaultShareClass = false;

// ******---------- Fonds BCJ Balancé ----------*****
const fondsBcjBalanceAa = {
  productId: "fonds-bcj-balance-aa",
  storeModule: "fondsBcjBalanceAa",
  productName: "Fonds BCJ Balancé AA - Distribution",
  productShortName: "Fonds BCJ Balancé",
  productColor: ORG_COLOR,
  productShareClassRoot: "fonds-bcj-balance",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const fondsBcjBalanceAt = cloneDeep(fondsBcjBalanceAa);
fondsBcjBalanceAt.productId = "fonds-bcj-balance-at";
fondsBcjBalanceAt.storeModule = "fondsBcjBalanceAt";
fondsBcjBalanceAt.productName = "Fonds BCJ Balancé AT - Capitalisation";
fondsBcjBalanceAt.isDefaultShareClass = false;

const fondsBcjBalanceP = cloneDeep(fondsBcjBalanceAa);
fondsBcjBalanceP.productId = "fonds-bcj-balance-p";
fondsBcjBalanceP.storeModule = "fondsBcjBalanceP";
fondsBcjBalanceP.productName = "Fonds BCJ Balancé P - Prévoyance";
fondsBcjBalanceP.isDefaultShareClass = false;

export const BCJ_PRODUCTS_DETAILS = [
  fondsBcjDefensifAa,
  fondsBcjDefensifAt,
  fondsBcjDefensifP,
  fondsBcjBalanceAa,
  fondsBcjBalanceAt,
  fondsBcjBalanceP,
];

export const BCJ_PRODUCTS = BCJ_PRODUCTS_DETAILS.map((details) => details.storeModule);
